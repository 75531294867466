/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { useBulkUpdateListingMutation } from '../../../components/api'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'
import InfoIconSvg from '../../../images/info-sign.svg'

/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP } = props
  const { listingIds = [] } = props

  // query
  const bulkUpdateListingMutation = useBulkUpdateListingMutation()

  // functions
  const onHide = () => {
    setOpenModal(false)
    bulkUpdateListingMutation.reset()
  }
  const internalOnSave = () => {
    deleteListings()
  }

  /**
   * Upload the product manifest file.
   */
  const deleteListings = () => {
    console.debug('Sending announcement to backend...')
    bulkUpdateListingMutation.mutate(listingIds.map(listingId => ({ listingId, formData: { 'listing[status]': 'deleted' } })))
  }

  return <>

        <Modal show={openModal} onHide={onHide}>
            <Modal.Body>
                <h5 className="m-0 mb-3"><img src={InfoIconSvg} className="mr-3" />Delete {listingIds.length} listings?</h5>

                <p>This cannot be undone.</p>

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">{bulkUpdateListingMutation.isSuccess ? 'Close' : 'Cancel'}</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary" disabled={bulkUpdateListingMutation.isSuccess}>
                        {bulkUpdateListingMutation.isLoading ? <Spinner /> : <span>Delete</span>}
                    </Button>
                </div>

                { bulkUpdateListingMutation.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Delete successful!</Alert> }
                { bulkUpdateListingMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error deleting listings. ' + bulkUpdateListingMutation.error.message}</Alert> }

            </Modal.Body>
        </Modal>
      </>
}

export default Page
